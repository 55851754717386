// this is for high tier coins
const randomizer_bid_low = {
  '00': 0.9981,
  '01': 0.9982,
  '02': 0.9984,
  '03': 0.998,
  '04': 0.9985,
  '05': 0.9983,
  '06': 0.9981,
  '07': 0.9983,
  '08': 0.9985,
  '09': 0.998,
  10: 0.9984,
  11: 0.9984,
  12: 0.9983,
  13: 0.9985,
  14: 0.9982,
  15: 0.9982,
  16: 0.9983,
  17: 0.9984,
  18: 0.9982,
  19: 0.9984,
  20: 0.9981,
  21: 0.9985,
  22: 0.9983,
  23: 0.998,
  24: 0.9982,
  25: 0.9983,
  26: 0.9983,
  27: 0.9983,
  28: 0.9984,
  29: 0.9983,
  30: 0.9982,
  31: 0.9983,
  32: 0.9983,
  33: 0.9981,
  34: 0.9983,
  35: 0.9982,
  36: 0.9981,
  37: 0.9981,
  38: 0.9984,
  39: 0.9981,
  40: 0.9981,
  41: 0.9984,
  42: 0.9985,
  43: 0.9983,
  44: 0.9983,
  45: 0.9981,
  46: 0.9983,
  47: 0.998,
  48: 0.9984,
  49: 0.9984,
  50: 0.9982,
  51: 0.9985,
  52: 0.9981,
  53: 0.9982,
  54: 0.9983,
  55: 0.9984,
  56: 0.9984,
  57: 0.9982,
  58: 0.9984,
  59: 0.9982,
  60: 0.9981,
  61: 0.9984,
  62: 0.9983,
  63: 0.9984,
  64: 0.9983,
  65: 0.9984,
  66: 0.9985,
  67: 0.998,
  68: 0.9981,
  69: 0.9982,
  70: 0.998,
  71: 0.9983,
  72: 0.9984,
  73: 0.9981,
  74: 0.9982,
  75: 0.9981,
  76: 0.9981,
  77: 0.9982,
  78: 0.9981,
  79: 0.998,
  80: 0.998,
  81: 0.998,
  82: 0.9982,
  83: 0.9982,
  84: 0.9981,
  85: 0.9983,
  86: 0.9982,
  87: 0.9982,
  88: 0.9983,
  89: 0.9981,
  90: 0.9985,
  91: 0.9982,
  92: 0.9981,
  93: 0.9983,
  94: 0.9982,
  95: 0.9983,
  96: 0.9985,
  97: 0.9984,
  98: 0.9982,
  99: 0.9982,
};
const randomizer_ask_low = {
  '00': 1.0019,
  '01': 1.0016,
  '02': 1.0016,
  '03': 1.0015,
  '04': 1.0019,
  '05': 1.0016,
  '06': 1.0017,
  '07': 1.0018,
  '08': 1.0018,
  '09': 1.002,
  10: 1.0019,
  11: 1.0018,
  12: 1.0019,
  13: 1.0016,
  14: 1.002,
  15: 1.0018,
  16: 1.0018,
  17: 1.0016,
  18: 1.0017,
  19: 1.0019,
  20: 1.0015,
  21: 1.0017,
  22: 1.0018,
  23: 1.0016,
  24: 1.0017,
  25: 1.0016,
  26: 1.0015,
  27: 1.0017,
  28: 1.0019,
  29: 1.0017,
  30: 1.0015,
  31: 1.0018,
  32: 1.0019,
  33: 1.002,
  34: 1.0015,
  35: 1.0019,
  36: 1.0019,
  37: 1.0016,
  38: 1.0016,
  39: 1.0017,
  40: 1.002,
  41: 1.0019,
  42: 1.0017,
  43: 1.002,
  44: 1.0016,
  45: 1.0015,
  46: 1.002,
  47: 1.0019,
  48: 1.0018,
  49: 1.002,
  50: 1.0019,
  51: 1.002,
  52: 1.0016,
  53: 1.002,
  54: 1.0018,
  55: 1.0017,
  56: 1.002,
  57: 1.0019,
  58: 1.0016,
  59: 1.0018,
  60: 1.0016,
  61: 1.0015,
  62: 1.0018,
  63: 1.0016,
  64: 1.002,
  65: 1.0017,
  66: 1.0018,
  67: 1.0019,
  68: 1.0016,
  69: 1.0018,
  70: 1.0019,
  71: 1.002,
  72: 1.002,
  73: 1.002,
  74: 1.0018,
  75: 1.0019,
  76: 1.0019,
  77: 1.0019,
  78: 1.002,
  79: 1.0016,
  80: 1.0015,
  81: 1.0018,
  82: 1.0015,
  83: 1.0015,
  84: 1.0016,
  85: 1.0019,
  86: 1.0017,
  87: 1.0018,
  88: 1.002,
  89: 1.002,
  90: 1.0017,
  91: 1.0019,
  92: 1.002,
  93: 1.0015,
  94: 1.002,
  95: 1.002,
  96: 1.0016,
  97: 1.0017,
  98: 1.0019,
  99: 1.0017,
};

// this is for mid tier coins
const randomizer_bid_temp = {
  '00': 0.9997,
  '01': 0.9996,
  '02': 0.9998,
  '03': 0.9997,
  '04': 0.9999,
  '05': 0.9998,
  '06': 0.9996,
  '07': 0.9995,
  '08': 0.9999,
  '09': 0.9995,
  10: 0.9999,
  11: 0.9994,
  12: 0.9996,
  13: 0.9995,
  14: 0.9995,
  15: 0.9995,
  16: 0.9995,
  17: 0.9996,
  18: 0.9996,
  19: 0.9998,
  20: 0.9996,
  21: 0.9997,
  22: 0.9999,
  23: 0.9997,
  24: 0.9998,
  25: 0.9997,
  26: 0.9996,
  27: 0.9995,
  28: 0.9995,
  29: 0.9996,
  30: 0.9996,
  31: 0.9998,
  32: 0.9999,
  33: 0.9999,
  34: 0.9998,
  35: 0.9994,
  36: 0.9995,
  37: 0.9998,
  38: 0.9996,
  39: 0.9994,
  40: 0.9997,
  41: 0.9995,
  42: 0.9995,
  43: 0.9994,
  44: 0.9997,
  45: 0.9994,
  46: 0.9996,
  47: 0.9995,
  48: 0.9995,
  49: 0.9996,
  50: 0.9997,
  51: 0.9996,
  52: 0.9998,
  53: 0.9999,
  54: 0.9994,
  55: 0.9998,
  56: 0.9994,
  57: 0.9999,
  58: 0.9998,
  59: 0.9994,
  60: 0.9998,
  61: 0.9996,
  62: 0.9998,
  63: 0.9996,
  64: 0.9996,
  65: 0.9997,
  66: 0.9997,
  67: 0.9996,
  68: 0.9995,
  69: 0.9996,
  70: 0.9994,
  71: 0.9999,
  72: 0.9995,
  73: 0.9999,
  74: 0.9998,
  75: 0.9998,
  76: 0.9998,
  77: 0.9999,
  78: 0.9996,
  79: 0.9997,
  80: 0.9999,
  81: 0.9998,
  82: 0.9996,
  83: 0.9995,
  84: 0.9996,
  85: 0.9995,
  86: 0.9996,
  87: 0.9995,
  88: 0.9999,
  89: 0.9995,
  90: 0.9995,
  91: 0.9995,
  92: 0.9997,
  93: 0.9994,
  94: 0.9995,
  95: 0.9996,
  96: 0.9996,
  97: 0.9995,
  98: 0.9997,
  99: 0.9996,
};
const randomizer_ask_temp = {
  '00': 1.0002,
  '01': 1.0001,
  '02': 1.0002,
  '03': 1.0002,
  '04': 1.0002,
  '05': 1.0002,
  '06': 1.0002,
  '07': 1.0002,
  '08': 1.0002,
  '09': 1.0001,
  10: 1.0001,
  11: 1.0002,
  12: 1.0001,
  13: 1.0003,
  14: 1.0002,
  15: 1.0002,
  16: 1.0002,
  17: 1.0003,
  18: 1.0003,
  19: 1.0003,
  20: 1.0001,
  21: 1.0003,
  22: 1.0002,
  23: 1.0003,
  24: 1.0002,
  25: 1.0003,
  26: 1.0002,
  27: 1.0002,
  28: 1.0003,
  29: 1.0002,
  30: 1.0003,
  31: 1.0002,
  32: 1.0002,
  33: 1.0003,
  34: 1.0001,
  35: 1.0003,
  36: 1.0001,
  37: 1.0002,
  38: 1.0003,
  39: 1.0003,
  40: 1.0002,
  41: 1.0002,
  42: 1.0002,
  43: 1.0002,
  44: 1.0002,
  45: 1.0002,
  46: 1.0002,
  47: 1.0003,
  48: 1.0002,
  49: 1.0003,
  50: 1.0003,
  51: 1.0003,
  52: 1.0003,
  53: 1.0002,
  54: 1.0002,
  55: 1.0001,
  56: 1.0002,
  57: 1.0002,
  58: 1.0002,
  59: 1.0002,
  60: 1.0002,
  61: 1.0002,
  62: 1.0001,
  63: 1.0003,
  64: 1.0002,
  65: 1.0002,
  66: 1.0002,
  67: 1.0002,
  68: 1.0002,
  69: 1.0001,
  70: 1.0002,
  71: 1.0003,
  72: 1.0003,
  73: 1.0002,
  74: 1.0001,
  75: 1.0002,
  76: 1.0001,
  77: 1.0002,
  78: 1.0002,
  79: 1.0001,
  80: 1.0002,
  81: 1.0003,
  82: 1.0001,
  83: 1.0003,
  84: 1.0001,
  85: 1.0002,
  86: 1.0002,
  87: 1.0002,
  88: 1.0002,
  89: 1.0002,
  90: 1.0001,
  91: 1.0003,
  92: 1.0002,
  93: 1.0001,
  94: 1.0003,
  95: 1.0002,
  96: 1.0003,
  97: 1.0001,
  98: 1.0002,
  99: 1.0003,
};

const randomizer_bid_volatile = {
  '00': 0.9987,
  '01': 0.9986,
  '02': 0.9988,
  '03': 0.9987,
  '04': 0.9989,
  '05': 0.9986,
  '06': 0.9986,
  '07': 0.9986,
  '08': 0.9989,
  '09': 0.9986,
  10: 0.9986,
  11: 0.9988,
  12: 0.9987,
  13: 0.9986,
  14: 0.999,
  15: 0.9988,
  16: 0.9987,
  17: 0.9985,
  18: 0.9987,
  19: 0.9989,
  20: 0.9987,
  21: 0.999,
  22: 0.9985,
  23: 0.9989,
  24: 0.9988,
  25: 0.9986,
  26: 0.9987,
  27: 0.9986,
  28: 0.9985,
  29: 0.9989,
  30: 0.9987,
  31: 0.9989,
  32: 0.9986,
  33: 0.9986,
  34: 0.9988,
  35: 0.9985,
  36: 0.9987,
  37: 0.9988,
  38: 0.9987,
  39: 0.9986,
  40: 0.9987,
  41: 0.9985,
  42: 0.9988,
  43: 0.9988,
  44: 0.9988,
  45: 0.9989,
  46: 0.9986,
  47: 0.9989,
  48: 0.9988,
  49: 0.9986,
  50: 0.9986,
  51: 0.9986,
  52: 0.9989,
  53: 0.9987,
  54: 0.9985,
  55: 0.9988,
  56: 0.9986,
  57: 0.9987,
  58: 0.9987,
  59: 0.9988,
  60: 0.9988,
  61: 0.9988,
  62: 0.999,
  63: 0.9985,
  64: 0.9986,
  65: 0.9986,
  66: 0.9987,
  67: 0.9988,
  68: 0.9989,
  69: 0.9987,
  70: 0.9987,
  71: 0.9987,
  72: 0.9989,
  73: 0.9986,
  74: 0.9986,
  75: 0.9986,
  76: 0.9986,
  77: 0.9986,
  78: 0.9988,
  79: 0.9987,
  80: 0.9988,
  81: 0.9986,
  82: 0.9989,
  83: 0.9989,
  84: 0.9987,
  85: 0.9986,
  86: 0.9986,
  87: 0.9987,
  88: 0.9987,
  89: 0.9989,
  90: 0.9985,
  91: 0.9985,
  92: 0.9985,
  93: 0.9988,
  94: 0.9988,
  95: 0.9985,
  96: 0.9987,
  97: 0.9988,
  98: 0.9989,
  99: 0.9987,
};

const mid_tier_coins = [
  'ADABUSD',
  'UNIBUSD',
  'MATICBUSD',
  'XRPBUSD',
  'LTCBUSD',
  'DOGEBUSD',
  'XLMBUSD',
  'EOSBUSD',
  'SOLBUSD',
  'DOTBUSD',
];
const high_tier_coins = [
  'HBARBUSD',
  'SXPBUSD',
  'COMPBUSD',
  'TRXBUSD',
  'LINKBUSD',
  'BATBUSD',
  'VETBUSD',
  'STORJUSDT',
  'MKRBUSD',
  'FETUSDT',
  'CHZBUSD',
  'DGBBUSD',
];
const volatile_coins = ['SOLBUSD', 'LUNABUSD', 'SHIBBUSD'];

export function price_bumper_ask(price, usdt_to_inr, coin) {
  let randomizer_bid = {};
  let timestamp = String(new Date().getTime() / 1000);
  let depth_enhancer = 1;

  if (volatile_coins.includes(coin)) {
    randomizer_bid = randomizer_bid_volatile;
    if (coin == 'SOLBUSD') {
      depth_enhancer = 0.999;
    }
  } else if (mid_tier_coins.includes(coin)) {
    randomizer_bid = randomizer_bid_temp;
    if (coin == 'XRPBUSD') {
      depth_enhancer = 0.999;
    } else if (coin == 'SOLBUSD') {
      depth_enhancer = 0.998;
    } else {
      depth_enhancer = 0.9945;
    }
  } else if (high_tier_coins.includes(coin)) {
    randomizer_bid = randomizer_bid_low;
    if (coin == 'LINKBUSD') {
      depth_enhancer = 0.9978;
    }
  } else {
    randomizer_bid = randomizer_bid_low;
  }

  let randomizer_quot = String(Math.floor(timestamp)).slice(-2);
  randomizer_quot =
    coin == 'SHIBBUSD'
      ? randomizer_bid[randomizer_quot] * price * usdt_to_inr * depth_enhancer
      : (
          randomizer_bid[randomizer_quot] *
          price *
          usdt_to_inr *
          depth_enhancer
        ).toFixed(2);
  if (Number.isFinite(parseFloat(randomizer_quot))) {
    return randomizer_quot;
  } else {
    return null;
  }
}

// Make requests to CryptoCompare API
export async function makeApiRequest(path) {
  try {
    const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
    return response.json();
  } catch (error) {
    throw new Error(`CryptoCompare request error: ${error.status}`);
  }
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  const short = `${fromSymbol}/${toSymbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
  };
}

export function parseFullSymbol(fullSymbol) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }

  return {
    exchange: match[1],
    fromSymbol: match[2],
    toSymbol: match[3],
  };
}
